import { IExam } from "../../models/exam/exam.model";
import { createReducer, on } from "@ngrx/store";
import * as ExamActions from '../actions/exam.actions';

export const examFeatureKey = 'EXAM_STATE_KEY';

export interface ExamState {
  exams: IExam[] | null
}

export const initialState: ExamState = {
  exams: null
};

export const examReducer = createReducer(
  initialState,
  on(ExamActions.setExams, (state, {exams}) => ({...state, exams}))
);
